<template>
  <div class="table-outer">
    <table>
      <thead>
        <tr>
          <th
            v-for="thItem in tableData.columns"
            :key="thItem.key"
            class="thHeadId"
          >
            <span>{{ thItem.label ? thItem.label : human(thItem.key) }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(trItem, index) in tableData.rows" :key="index" class="thRowId">
          <td v-for="(tdItem, index) in tableData.columns" :key="index">
            <span>{{ trItem[tdItem.key] }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "StaticTable",
  props: {
    tableData: {
      type: Object,
      default() {
        return {
          columns: [],
          rows: [],
        };
      },
    },
  },
  methods: {
    /**
     * Create a human readable version of key.
     * @param value
     * @returns {string}
     */
    human: (value) => {
      let words = value.match(/[A-Za-z0-9][a-z0-9]*/g) || [];
      return words
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.substring(1);
        })
        .join(" ");
    },
  },
};
</script>

<style scoped lang="scss">
.table-outer {
  color: #fff;
  @include scrollbars($radius: 10px, $size: 15px);
  overflow-x: auto;
  width: 100%;
  margin-left: 50%;
  transform: translateX(-50%);

  table::v-deep {
    width: 105%;
    // table-layout: fixed;

    // *new
    @media (min-width: 1200px) {
        thead th {
          position: sticky;
          top: 0;
          z-index: 1;
        }
    
        // new
        tbody th {
          position: relative;
        }
    
        // new
        thead th:first-child {
          position: sticky;
          left: 0;
          z-index: 2;
          // background-color: red;
        }
    
        // new
        thead th:nth-child(2) {
          position: sticky;
          left: 160px;
          z-index: 2;
        }
    
        // new
        tbody td:first-child {
          position: sticky;
          left: 0;
          z-index: 2;
        }
    
        // new
        tbody td:nth-child(2) {
          position: sticky;
          left: 160px;
          z-index: 2;
        }
    
        // new
        tbody th {
          position: sticky;
          left: 0;
          z-index: 1;
        }
    }

    thead,
    tbody {
      tr {
        &:nth-child(2n) {
          td {
            padding: 5px;
            background: var(--medium-dark);
          }
        }

        th,td {
          padding: 5px;
          background: var(--background);
          min-width: 150px;

          // position: relative;
          overflow: hidden;

          &.empty {
            background: transparent;
            width: 15px;
          }
        }

        th {
          background: var(--background-light);
          // position: relative;

          > * {
            display: inline-flex;
          }

          &[aria-sort="ascending"] {
            &:after {
              content: "A";
            }
          }

          &[aria-sort="descending"] {
            &:after {
              content: "D";
            }
          }

          &[aria-sort="ascending"],
          &[aria-sort="descending"] {
            &:after {
              margin-left: 10px;
              //position: absolute;
              //right: 0;
            }
          }

          .sr-only {
            display: none;
          }
        }
      }
    }
  }
}
</style>
