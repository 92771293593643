<template>
  <div class="container">
    <img @click="redirect()" :src="profileImg" />

    <div class="comment">
      <h3 @click="redirect()">{{ username }}</h3>
      <p v-html="formatted"></p>
      <LinkPreview
        v-if="preview_url !== null"
        :title="preview_title"
        :img="preview_img"
        :url="preview_url"
      />
    </div>
  </div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import LinkPreview from "@/components/post/LinkPreview"

export default {
  name: "Comment",
  components: { LinkPreview },
  props: {
    id: Number,
    UID: Number,
    text: String,
    date_commented: Number,
    username: String,
    img_url: String,
    preview_img: String,
    preview_title: String,
    preview_url: String,
  },
  methods: {
    async redirect() {
      const getHash = await this.$api.getHash(this.UID, this.userStore.accessToken);
      if(getHash.success) {
        const hash = getHash.hash.hash_email;
        this.$router.push({ path: `/user/${this.username}-${hash}` });
      } else {
        this.$router.push({ path: `/` });
      }
    },
  },
  computed: {
    ...mapStores(useUserStore),
    formatted() {
      const urlMatcher = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gim
      return this.text.replace(
        urlMatcher,
        (match) =>
          `<a target="_blank" rel="noopener noreferrer" class="stylingLink" href="${match}">${match}</a>`
      )
    },
    profileImg() {
      return this.$formatter.formatProfileImg(this.img_url);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;

  text-align: left;
  padding: 10px;

  img {
    @extend .pointer;
    @extend .nodrag;
    @extend .noselect;
    @include profile-img(40px);
    min-width: 40px;
    min-height: 40px;
    margin-right: 10px;
  }
  .comment {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #fff;
    width: auto;

    margin-right: 10px;
    padding: 10px;

    background-color: var(--dark);
    border-radius: 0 15px 15px 15px;

    h3 {
      @include dot-overflow;
      @extend .pointer;
      margin: 0;
      margin-bottom: 5px;
    }
    p {
      margin: 0;
      word-break: break-all;
    }
  }
}
</style>
