<template>
  <div class="container">
    <h3>Upcoming events</h3>

    <div class="eventContainer" v-if="events.length > 0">
      <div
        class="event"
        v-for="event in events"
        :key="event.id"
        @click="openEventDetails(event.id)"
      >
        <img src="@/assets/icons/calendar.svg" />
        <h4>{{ event.title }}</h4>
        <div v-if="event.eventStart === event.eventEnd" class="single-day-event">
          <h5>{{ showEventDate(event, true) }}</h5>
        </div>
        <div v-else class="multi-day-event">
          <h5>{{ showEventDate(event, false) }}</h5>
        </div>
      </div>
    </div>
    <h4 v-else>There is no upcoming events.</h4>
  </div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";

export default {
  name: "UpcomingEvents",
  props: {
    tagName: {
      type: String,
      required: false,
      default: null
    }
    
  },  
  data() {
    return {
      events: [],
    };
  },
  mounted() {
    this.getData();
  }, 
  watch: {
    async tagName() {
      await this.getData();
    }
  },
  computed: {
    ...mapStores(useUserStore),
  },
  methods: { 
    async getData() {
      if(this.tagName === null) {
        const tagIds = this.$store.getSubscribed().map(tag => tag.id_tag);
        await this.$api.getUpcomingEvents(this.userStore.user.id, tagIds, this.userStore.accessToken)
          .then(response => {
            if (response.success === true) {
              this.events = response.events;
            }
          })
      } else {
          await this.$api.getUpcomingEventsForTag(this.tagName, this.userStore.accessToken)
            .then(response => {
              if (response.success === true) {
                this.events = response.events;
              }
            })
      }
    },
    openEventDetails(id) {
      if(this.tagName === null) {
        this.$router.push({ path: "calendar", query: { eventId: `${id}` } });
      } else {
        this.$router.push({ path: "/calendar/" + this.tagName, query: { eventId: `${id}` }});
      }
    },
    showEventDate(event, isSingle = true) {
      if(isSingle) {
        let time = event.eventStart.split(" ")[1].split(":");
        return `${this.$formatter.formatDateTime(event.eventStart, false)} at ${time[0]}:${time[1]}`;
      } 

      return `${this.$formatter.formatDateTime(event.eventStart, false)} - ${this.$formatter.formatDateTime(event.eventEnd, false)}`;
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  @include side-component;

  // min-height: 200px;
  padding: 15px;
  text-align: center;

  h3 {
    @include side-component-title;
    margin-bottom: 15px;
  }
  h4 {
    font-size: 0.9em;
    color: var(--light);
    margin-top: 30px;
  }

  .eventContainer {
    display: flex;
    flex-direction: column;

    .event {
      display: grid;
      grid-template-columns: 40px auto;
      grid-template-rows: auto auto;
      gap: 0px 0px;
      grid-template-areas:
        "image title"
        "image date";

      text-align: left;
      margin-bottom: 10px;
      padding: 4px 4px;

      img {
        grid-area: image;
        width: 25px;
      }
      h4 {
        @include dot-overflow;
        grid-area: title;
        margin: 0;
        color: var(--text);
      }
      h5 {
        grid-area: date;
        margin: 0;
        color: var(--text-darker);
      }

      &:hover {
        cursor: pointer;
        border-radius: 5px;
        // padding: 4px 4px;
        background-color: rgba(grey, $alpha: 0.2);
      }
    }
  }
}
</style>
