<template>
    <article v-if="!isDeleted" class="TagPost" :id="createUniqueId()">
        <div class="TagPost-info">
            <img class="TagPost-info--profileImg" :src="profileImg" @click="redirect()" alt="Profile image">

            <h3 class="TagPost-info--username" @click="redirect()">
                {{ post.user.username }}
            </h3>
            <h4 class="TagPost-info--profileType">
                {{ post.user.username === 'Nobody' ? 'No profile': 'Personal profile'}}
            </h4>

            <div 
                class="post-controls" 
                v-if="post.id_user === this.$store.getId() || this.$store.getLevel() === 10">
                <b-dropdown dropleft id="post-controls-tags">
                    <template #button-content>
                        <b-icon-chevron-down></b-icon-chevron-down>
                    </template>
                    <b-dropdown-item-button @click="showDeleteModal = true">
                        Remove
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-trash"
                            viewBox="0 0 16 16"
                        >
                            <path
                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                            />
                            <path
                                fill-rule="evenodd"
                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                            />
                        </svg>
                    </b-dropdown-item-button> 
                </b-dropdown>
                <div :class="{ show: showDeleteModal }" class="remove-modal">
                    <div class="box">
                        <span>
                            This will permanently remove the selected post. Do you wish to continue?
                        </span>
                        <div class="controls">
                            <button type="button" @click="deletePost">Yes</button>
                            <button type="button" @click="showDeleteModal = false">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="TagPost-tags">
            <div v-for="(tag, ind) in post.tags" @click="redirectTagView(tag)" class="TagPost-tags-tag" :class="searchedTag === tag.name ? 'TagPost-tags-active' : null">
                <span>{{tag.name}}</span>
            </div>
        </div>
        <div class="TagPost-content">
            <p v-html="formated"></p>
            <div class="TagPost-content--image">
                <img 
                    @click="openPost()"
                    v-if="post.img_url !== null && post.img_url !== undefined && post.img_url !== ''" 
                    ref="postImage" 
                    id="postImg" 
                    alt="User uploaded image"
                    :src="post.img_url" />
            </div>

            <LinkPreview 
                v-if="!(post.img_url !== null && post.img_url !== undefined && post.img_url !== '')"
                :title="post.preview_title"
                :img="post.preview_img"
                :url="post.preview_url"
            />
        </div>
        <div class="TagPost-postInfo">
            <p @click="openPost()">{{ setComments }}</p>
            <p @click="openPost()">{{ $formatter.formatTimeSince(post.date_posted) }}</p>
        </div>

        <CommentForm 
            :postId="post.id"
            :postOwnerId="post.id_user"
            :isGroupPost="post.group"
            :groupId="post.id_group"
            @click.native="openPost(true)"
            @refresh="refreshComments"
        />

        <div v-if="showOpenPost" class="TagPost-openPost">
            <OpenPostView :postID="post.id" @openPostClosed="openPostClosedH" @newComment="refreshComments" />
        </div>
    </article>
    <article class="deleted" v-else>
        This post has been successfully deleted.
    </article>
</template>
<script>
// Stores
import { useUserStore } from "@/stores/UserStore";
import { mapWritableState, mapStores } from "pinia";
import { useTagStore } from "@/stores/IsolatedTagStore";
// Components
import LinkPreview from "@/components/post/LinkPreview";
import CommentForm from "@/components/post/CommentForm";
import OpenPostView from "@/views/OpenPostView"

import {
  BDropdown,
  BDropdownDivider,
  BDropdownItemButton,
  BIconChevronDown,
} from "bootstrap-vue";


export default {
    props: ['post', 'searchedTag'],
    components: {
    LinkPreview,
    CommentForm,
    OpenPostView,
    BDropdown,
    BDropdownDivider,
    BDropdownItemButton,
    BIconChevronDown,
    },
    data() {
        return {
            comments: [],
            showOpenPost: false,
            isDeleted: false,
            showDeleteModal: false
        }
    },
    mounted() {
        this.getComments();
    },
    methods: {
        openPost(clickOnInp = false) {
            this.$emit("postOpened")
            this.showOpenPost = true;
            this.openedPost = `#post-${this.post.id}`;
            this.$router.push({ path: `/post/${this.post.id}`, query: { focusInput: clickOnInp, inIsolatedTag: true, isolatedTag: this.searchedTag } })
        },
        openPostClosedH() {
            this.showOpenPost = false;
            this.$emit("closedPost");
            this.openedPostViewCloseHandler = true;
        },
        async getComments() {
            let response = await this.$api.getComments(this.post.id, this.userStore.accessToken);

            if (response.success === true) {
                this.comments = response.comments;
            } else {
                this.comments = []
            }
        },
        async refreshComments() {
            this.getComments();
        },
        async redirect() {
            this.$emit('closedPost');
            if(this.post.id_user > 0) {
                const getHash = await this.$api.getHash(this.post.id_user, this.userStore.accessToken);
                const hash = getHash.hash.hash_email;
                this.$router.push({ path: `/user/${this.post.user.username}-${hash}`})
            }
        },
        redirectTagView(tag) {
            if(!(tag.name === this.searchedTag)) {
                const store = useTagStore();
                store.$reset();
                this.$router.push({ path: `/tag/${tag.name}`});
            }
        },  
        async deletePost() {
            const response = await this.$api.updatePost(
                {
                    id: this.post.id,
                    deleted: true,
                },
                this.$store.getId()
            )
            if (response.success === true) {
                // update the state
                this.isDeleted = true
            } 
        },
        createUniqueId() {
            return `post-${this.post.id}`;
        }
    },
    computed: {
        ...mapStores(useUserStore),
        profileImg() {
            return this.$formatter.formatProfileImg(this.post.user.user_img);
        },
        formated() {
            let message = this.post.text;
            const urlMatcher = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gim
            return message.replace(
                urlMatcher,
                (match) =>
                `<a target="_blank" rel="noopener noreferrer" style="color: orange" href="${match}">${match}</a>`
            )
        },
        setComments() {
            return this.comments.length === 1
                ? this.comments.length + " Comment"
                : this.comments.length + " Comments";
        },
        ...mapWritableState(useTagStore, ['openedPost']),
    }
}
</script>
<style lang="scss" scoped>
    
    .TagPost {
        border-top: 3px solid var(--dark);
        padding-top: 10px;
        margin-top: 15px;

        &-info {
            display: grid;
            grid-template-columns: 70px auto 50px;
            grid-template-rows: auto auto;
            grid-template-areas:
            "image name controls"
            "image type bottomControls";

            padding: 8px;
            text-align: left;

            &--profileImg {
                grid-area: image;
                @extend .pointer;
                @extend .nodrag;
                @extend .noselect;

                @include profile-img(60px);
                @include box-shadow($color: rgba(0, 0, 0, 0.2));
            }

            &--username {
                grid-area: name;
                @extend .pointer;
                @include dot-overflow;
                grid-area: name;
                margin: 0;
                margin-top: 5px;

                font-size: 1.3em;
            }

            &--profileType {
                grid-area: type;
                margin: 0;
                font-size: 0.8em;
                font-weight: normal;
            }

            .post-controls {
                grid-area: controls;

                .b-dropdown::v-deep {
                    > button {
                    transform: rotateZ(0deg);
                    line-height: 0;
                    transition: all 0.3s;
                        &:hover {
                            transform: rotateZ(0deg) scale(1.05, 1.05) !important;
                        }
                    }
                    &.show {
                    > button {
                            transform: rotateZ(90deg) !important;
                        }
                    }
                    button {
                        @include button(10px, false, var(--medium));
                    }
                    .dropdown-menu {
                        position: absolute;
                        display: none;
                        z-index: 10;
                        background: var(--background-dark);
                        padding: 8px 0;
                        box-shadow: 3px 3px 10px var(--background-dark);
                        outline: none;
                    &.show {
                        display: initial;
                    }
                    li {
                        display: block;
                        width: 100%;
                        padding: 0;
                        margin: 0;
                        button {
                        display: flex;
                        justify-content: space-between;
                        width: inherit;
                        margin: 0;
                        background: transparent;
                        border-radius: 0;
                        white-space: nowrap;
                        padding: 4px 10px;
                            &:hover {
                                background: var(--medium);
                            }
                            svg {
                                margin-left: 10px;
                            }
                        }
                    }
                    }
                }
            }

            .remove-modal {
                z-index: 100;
                position: fixed;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background: var(--background-light);
                padding: 32px;
                border-radius: 15px;
                text-align: center;
                box-shadow: 0 4px 30px var(--background-dark);
                display: none;
                &.show {
                    display: grid;
                }
                grid-template-columns: auto;
                grid-template-rows: auto auto;
                grid-template-areas:
                "text"
                "buttons";
                span {
                    grid-area: text;
                }
                .controls {
                    grid-area: buttons;

                    button {
                        @include button(10px, false);
                        margin-top: 2em;
                    }
                }
            }
        }

        &-tags {
            display: flex;
            flex-wrap: wrap;
            margin-top: 5px;            

            &-tag {
                background-color: var(--dark);
                border-radius: 15px;

                padding: 8px;
                margin-right: 5px;
                margin-top: 5px;
                font-size: .8em;

                cursor: pointer;
            }

            &-active {
                background-color: var(--orange);
            }
        }

        &-content {
            font-size: 1.2em;
            word-wrap: break-word;
            padding: 5px;
            height: auto;

            p {
                text-align: left;
                margin-top: 15px;
                margin-bottom: 25px;
            }

            &--image {
                display: flex;

                img#postImg {
                    @include box-shadow($color: rgba(0, 0, 0, 0.3));
                    @extend .pointer;
                    max-width: 100%;
                    border-radius: 15px;
                    margin: 0 auto;
                }
            }
        }

        &-postInfo {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 5px;


            p {
                color: var(--light);
                font-size: 0.9em;
                margin: 0;
                padding: 10px;
                max-width: 400px;
                cursor: pointer;
            }
        }

        &-openPost {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 99999;
            height: 100vh;
            width: 100%;
            overflow: auto;
            background-color: black;
        }
    }
    @keyframes removedFadeout {
        0% {
            opacity: 1;
            height: 5em;
        }
        80% {
            opacity: 1;
            height: 5em;
        }
        100% {
            opacity: 0;
            height: 0;
        }
        }

    .deleted {
        height: 5em;
        line-height: 5em;
        padding-top: 0;
        margin-bottom: 0;
        overflow: hidden;
        color: var(--text-darker);
        text-align: center;
        border-top: 3px solid var(--dark);
        padding-top: 10px;
        margin-top: 15px;
        animation: removedFadeout 5s ease-out both;
    }
</style>