<template>
  <div>
    <div class="comments" v-if="comments.length > 0">
      <Comment
        v-for="comment in comments"
        :key="comment.id"
        v-bind="comment"
      />
    </div>
    <p v-if="comments.length === 0">
      Be the first person to comment on a post.
    </p>
  </div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import Comment from "@/components/post/Comment";

export default {
  name: "Comments",
  components: { Comment },
  props: {
    postId: Number,
    refreshComments: Number,
  },
  data() {
    return {
      comments: [],
      showAll: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let response = await this.$api.getComments(this.postId, this.userStore.accessToken);

      if (response.success === true) {
        this.comments = response.comments;
        this.$emit("commentsNumber", this.comments.length);
      }
    },
    toggle() {
      this.showAll = !this.showAll;
    },
  },
  computed: {
    ...mapStores(useUserStore)
  },
  watch: {
    refreshComments() {
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.comments {
  @include scrollbars($radius: 10px, $size: 15px);
  max-height: 600px;
  overflow-y: auto;
}
p {
  @extend .pointer;
  @extend .noselect;
  @extend .nodrag;
  color: #fff;
  margin-left: 10px;
}
</style>
