<template>
  <div class="postTypeInfo__container">
    <div class="postTypeInfo">
      <div class="postTypeInfo__img" @click="pushProfile">
        <img :src="profileImg" alt="identityPhoto" />
        <!-- <img
          src="https://images.squarespace-cdn.com/content/v1/579fbb52197aea2fa0c7d1e8/1607919012437-AFHWAIMTRTFWOTFYY1XN/Austin+Sills-Trausch+Media+Kit+3.0+JPG.jpg?format=1000w"
          alt=""
        /> -->
      </div>
      <div class="postTypeInfo__text">
        <h3 v-if="post.group">
          <span @click="pushUserProfile">{{ post.username }}</span>
          <span @click="pushGroup"> ({{ createGroupName }} </span>
          <span @click="pushGroup" style="color: rgb(253, 142, 0);"
            >~ {{ createTabName }}</span
          >
          <span>)</span>
        </h3>
        <h3 v-else v-html="postTitle" @click="pushUserProfile()"></h3>
        <h4>{{ postType }}</h4>
      </div>
    </div>
    <div v-if="!(post.admin || post.group)" class="tagsForTypeInfo">
      <span class="tagForTypeInfo" v-for="tag in formatedTags" :key="tag">
        <router-link :to="'/tag/' + tag">{{ tag }}</router-link>
      </span>
    </div>
    <div class="postDescription">
      <p
        v-html="getDescFromTbl"
        v-linkified.options="{ className: 'stylingLink' }"
      ></p>
    </div>
  </div>
</template>

<script>
import linkify from "vue-linkify";

export default {
  name: "PostTypeInfo",
  directives: {
    linkified: linkify,
  },
  props: {
    post: Object,
    tags: Array,
  },
  methods: {
    pushProfile() {
      this.$emit("pushProfile");
    },
    pushUserProfile() {
      if(!this.post.admin) {
        this.$emit("pushUserProfile");
      }
    },
    pushGroup() {
      this.$emit("pushGroup");
    },
  },
  computed: {
    profileImg() {
      if (this.post.group && this.user_img === "")
        return require("@/assets/img/defaultGroup.jpg");
      if (this.post.type === "pinned" || this.post.admin) {
        if (this.$store.adminImgExists()) {
          let adminImg = this.$store.getAdminImg();
          if (adminImg) {
            return adminImg;
          } else {
            return require("@/assets/img/default.jpg");
          }
        } else {
          return require("@/assets/img/default.jpg");
        }
      }

      return this.$formatter.formatProfileImg(this.post.user_img);
    },
    createGroupName() {
      return this.post.groupInfo.name;
    },
    createTabName() {
      return this.post.groupInfo.tabName;
    },
    postTitle() {
      if (this.post.admin) {
        return this.$store.getAdminPostName();
      } else {
        return this.post.username;
      }
    },
    postType() {
      if (this.post.type === "pinned" || this.post.admin) {
        return "by EFAN admins";
      } else if (this.post.group) {
        return "Group post";
      }
      return "Personal profile";
    },
    getDescFromTbl() {
      if(this.post.type === 'table') {
        let tableData = JSON.parse(this.post.text)
        return tableData.msg
      }
      else {
        return this.post.text
      }
    },
    formatedTags() {
      const tagArr = [];
      for (let tag of this.tags) {
        tagArr.push(tag[0].name);
      }
      return tagArr;
    }
  },
};
</script>

<style lang="scss">
.removeMargin {
  margin-left: 0 !important;
}

.postTypeInfo {
  text-align: start;
  margin-top: 20px;
  margin-left: 30px;
  display: flex;

  @include respond-to('small') {
    margin-top: 5px;
  }

  @include respond-to('large') {
    margin-left: 0;
  }

  &__img {
    img {
      margin-right: 10px;
      @extend .pointer;
      @extend .nodrag;
      @extend .noselect;

      @include profile-img(60px);
      @include box-shadow($color: rgba(0, 0, 0, 0.2));
    }
  }

  &__text {
    overflow: hidden;
    &:hover {
      cursor: pointer;
    }
    h3 {
      grid-area: name;
      @include dot-overflow;
      margin: 0;
      margin-top: 5px;
      font-size: 1.3em;
      color: #fff;
      word-wrap: break-word;
    }

    @media screen and (max-width: 350px) {
      ::v-deep span {
        display: none;
      }
    }

    h4 {
      grid-area: type;
      margin: 0;
      font-size: 0.8em;
      font-weight: normal;
      color: #fff;
    }
  }
}

.tagsForTypeInfo {
  grid-area: tags;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-left: 30px;
  max-width: 700px;
  // cursor: pointer;

  @include respond-to('large') {
    margin-left: 0;
  }

  .tagForTypeInfo {
    padding: 8px;
    margin-right: 5px;
    margin-top: 4px;
    border-radius: 15px;
    background-color: var(--dark);
    font-size: 0.8em;

    a {
      text-decoration: none;
      color: #fff;
    }
  }
}
.postDescription {
  text-align: start;
  color: #fff;
  margin: 0 20px 0 30px;
  word-wrap: break-word;

  @include respond-to('large') {
    margin-left: 0;
  }
  @include respond-to('small') {
    margin-right: 0;
  }
}
</style>
