<template>
  <div
    class="modal"
    ref="modalForFocus"
    tabindex="1"
    @keydown.esc="closePost()"
  >
    <a :href="openedPost">
      <img
        class="closeButton"
        src="@/assets/icons/close-button.svg"
        @click="closePost"
      />
    </a>
    <!-- V primeru da ni tabele pokaži ta layout -->
    <div v-if="tableData.columns.length === 0" class="container">
      <div>
        <post-type-info
          :post="post"
          :tags="tags"
          @pushProfile="openProfile"
          @pushUserProfile="openUserProfile"
          @pushGroup="openGroup"
        ></post-type-info>
        <div v-if="post.img_url" class="userPost-img">
          <img :src="post.img_url" alt="Users uploaded photo" />
        </div>
        <div v-else class="postLink">
          <LinkPreview
            :url="post.preview_url"
            :img="post.preview_img"
            :title="post.preview_title"
          />
        </div>
      </div>
      <div class="comments__container">
        <div class="postComments" v-if="post.id > 0">
          <p style="color: white;">{{ setCommentsNumber }}</p>
          <CommentForm
            :postId="post.id"
            :postOwnerId="post.id_user"
            :isGroupPost="post.group"
            :groupId="post.id_group"
            :clickOnInput="clickedOnInput"
            @refresh="newComment"
          />
          <Comments
            :postId="post.id"
            @commentsNumber="getNumber"
            :refreshComments="commentKey"
          />
          </div>
        </div>
    </div>
    <div v-else class="container-table">
      <div class="static-table-container">
        <static-table :tableData="tableData"></static-table>
      </div>
      <div>
        <post-type-info 
            :post="post"
            :tags="tags"
            @pushProfile="openProfile"
            @pushUserProfile="openUserProfile"
            @pushGroup="openGroup">
        </post-type-info>
        <div v-if="post.img_url" class="postImgInDesc">
          <!-- <img
              src="https://www.destructoid.com/wp-content/uploads/2021/09/Lost-in-Random-Shadowman-screenshot.jpg"
              alt="Users photo" /> -->
              <img :src="post.img_url" alt="Image in description">
        </div>
        <div v-if="post.preview_img" class="postLink">
          <LinkPreview
            :url="post.preview_url"
            :img="post.preview_img"
            :title="post.preview_title"
          />
        </div>
        <div class="postComments" style="margin:0 auto; color: white;" v-if="post.id > 0">
            <p>{{ setCommentsNumber }}</p>
            <CommentForm
              :postId="post.id"
              :postOwnerId="post.id_user"
              :isGroupPost="post.group"
              :groupId="post.id_group"
              :clickOnInput="clickedOnInput"
              @refresh="newComment"
            />
            <Comments
              :postId="post.id"
              @commentsNumber="getNumber"
              :refreshComments="commentKey"
            />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import CommentForm from "../components/post/CommentForm.vue";
import Comments from "../components/post/Comments.vue";
import LinkPreview from "../components/post/LinkPreview.vue";
import PostTypeInfo from "../components/PostTypeInfo.vue";
import StaticTable from "../components/StaticTable.vue";

import { mapWritableState, mapState } from "pinia";
import { useTagStore } from "@/stores/IsolatedTagStore";

export default {
  name: "OpenPostView",
  components: { LinkPreview, CommentForm, Comments, PostTypeInfo,StaticTable },
  props: ["postID"],
  data() {
    return {
      post: {},
      tags: [],
      tableData: {
        columns: [],
        rows: [],
      },
      checkForFoto: false,
      errorMsg: "",
      numOfComments: 0,
      commentKey: 0,
      clickedOnInput: false,
    };
  },
  async mounted() {
    this.$refs.modalForFocus.focus();
    this.checkQuery();
    this.getPost();
    this.setTags();
  },
  methods: {
    addPathToLocation(params) {
      history.pushState({}, null, this.$route.path + `post/${params}`);
    },
    async closePost() {
      if (this.$route.query.inGroup) {
        this.$router.push(
          `/group/${this.post.id_group}/tab/${this.post.tab_id}`
        );
      } else if (this.$route.query.inUserProfile) {
        const getHash = await this.$api.getHash(this.post.id_user, this.userStore.accessToken);
        const hash = getHash.hash.hash_email;
          this.$router.push({ path: `/user/${this.post.username}-${hash}`})
      } else if (this.$route.query.inIsolatedTag) {
        this.openedPostViewCloseHandler = true;
        this.$router.push(`/tag/${this.$route.query.isolatedTag}`);
      } else {
        this.$router.push("/");
      }

      this.$emit("openPostClosed");
    },
    async openUserProfile() {
      this.$emit("openPostClosed");
      const getHash = await this.$api.getHash(this.post.id_user, this.userStore.accessToken);
      const hash = getHash.hash.hash_email;
      this.$router.push({ path: `/user/${this.post.username}-${hash}`})
    },
    openGroup() {
      this.$emit("openPostClosed");
      this.$router.push(`/group/${this.post.id_group}/tab/${this.post.tab_id}`);
    },
    async openProfile() {
      if (this.post.admin === 0) {
        this.$emit("openPostClosed");
        const getHash = await this.$api.getHash(this.post.id_user, this.userStore.accessToken);
        const hash = getHash.hash.hash_email;
        this.$router.push({ path: `/user/${this.post.username}-${hash}`})
      }
    },

    checkQuery() {
      if (this.$route.query.focusInput === "true") {
        this.clickedOnInput = true;
      } else {
        this.clickedOnInput = false;
      }
    },

    getNumber(num) {
      this.numOfComments = num;
    },
    async setTags() {
      const response =
        this.$route.params.id !== undefined
          ? await this.$api.getPostSelectedTags(this.$route.params.id)
          : await this.$api.getPostSelectedTags(this.postID);
      if (response.success) {
        this.tags = response.tags;
      } else {
        this.tags = [];
      }
    },
    newComment() {
      this.commentKey++;
      this.$emit("newComment");
    },
    human: (value) => {
      let words = value.match(/[A-Za-z0-9][a-z0-9]*/g) || [];
      return words
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.substring(1);
        })
        .join(" ");
    },
    async getPost() {
      const response =
        this.$route.params.id !== undefined
          ? await this.$api.getPost(this.$route.params.id)
          : await this.$api.getPost(this.postID);
      if (response.success) {
        this.post = response.post;
        if(this.post.type === 'table') {
          let tableData = JSON.parse(this.post.text)
          this.tableData.columns = tableData.tbl.columns
          this.tableData.rows = tableData.tbl.rows
        }
        if (this.$route.path == "/") {
          this.addPathToLocation(this.post.id);
        }
      } else {
        this.errorMsg = response.msg;
      }
    },
  },
  computed: {
    setCommentsNumber() {
      return this.numOfComments === 1
        ? `${this.numOfComments} Comment`
        : `${this.numOfComments} Comments`;
    },
    ...mapWritableState(useTagStore, ['openedPostViewCloseHandler']),
    ...mapState(useTagStore, ['openedPost']),
    ...mapStores(useUserStore),
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 100;
  background-color: rgb(44, 45, 49);
  display: flex;
  justify-content: center;
  @include scrollbars($radius: 10px, $size: 15px);

  .closeButton {
    @extend .pointer;
    @extend .noselect;
    @extend .nodrag;
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 10;

    &:hover {
      @include popup();
    }

    @include respond-to('small') {
      left: 10px;
      top: 10px;
    }
  }
}

.container {
  width: 100%;
  margin-top: 80px;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: 1fr 2fr 2fr;

  @include respond-to('large') {
    grid-template-columns: 100%;
    grid-template-rows: repeat(3, 1fr);
    width: 90%;
    margin: 0 auto;
    margin-top: 80px;
  }

  .userPost-img {
    padding: 10px 50px 50px 50px;

    @include respond-to('small') {
      padding: 0px;
    }

    img {
      border-radius: 15px;
      height: auto;
      max-height: 70vh;
      width: auto;
      max-width: 100%;
    }
  }

  .postComments {
    text-align: start;
    min-width: 250px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;

    p {
      color: #fff !important;
    }
  }
}

.container-table {
  width: 100%;
  margin-top: 80px;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: 1fr;

  @include respond-to('large') {
    grid-template-columns: 100%;
    grid-template-rows: repeat(3, 1fr);
    width: 90%;
    margin: 0 auto;
    margin-top: 80px;
  }

  .static-table-container {
    width: 90%;
    margin: 0 auto;

    @include respond-to('large') {
      width: 100%;
    }
  }


  .postImgInDesc {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;

    img {
      max-height: 30vh;
      max-width: 95%;
      height: auto;
      width: auto;
      border-radius: 10px;
    }
  }
}

.postLink {
    grid-area: postImageOrLink;
    text-align: center;

    a {
      width: 95%;
      max-width: 700px;
      max-height: 500px;
    }
  }
</style>
