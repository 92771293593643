<template>
    <div class="isolated-tag">
        <Navbar class="navbar" />
        <div class="isolated-tag-layout">
            <div class="isolated-tag-header">
                <div class="isolated-tag-header--title">
                    <h1>#{{ tagName }}</h1>
                </div>
                <div @click="closeTag" class="isolated-tag-header--options">
                    <div class="isolated-tag-header--options--bg"></div>    
                </div>
            </div>
            <div class="isolated-tag-events">
                <upcoming-events :tag-name="tagName" />
            </div>
            <div v-if="!initalLoading" class="isolated-tag-posts">
                <tag-post 
                    v-for="(post, ind) in posts"
                    :key="createUniqueKey(post.id, ind)" 
                    :post="post" 
                    :searchedTag="tagName"
                    @closedPost="fullPostClosed" />
            </div>
            <div v-if="(initalLoading || infinityLoading) && error === null" class="isolated-tag-loader">
                <div class="loader">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div v-if="error !== null" class="isolated-tag-error">
                <h4>{{ error }}</h4>
            </div>
        </div>
    </div>
</template>
<script>
// Store context
import { mapStores } from "pinia";
import { mapWritableState } from "pinia";
import { useUserStore } from "@/stores/UserStore";
import { useTagStore } from "@/stores/IsolatedTagStore";
// Components
import Navbar from "@/components/Navbar";
import TagPost from "../components/post/TagPost.vue";
import UpcomingEvents from "@/components/side/UpcomingEvents.vue";


export default {
    name: "Tag",
    components: { Navbar, TagPost, UpcomingEvents },
    props: {
        tagName: {
            type: String,
            default() {
                return ''
            }
        }
    },
    beforeRouteUpdate(to, from, next) {
        if(!(to.hash)) {
            const store = useTagStore();
            store.$reset();
            this.firstTimeOpened = true;
            this.error = '';
            this.fetchData(to.params.tagName);
        }
        next();
    }, 
    beforeRouteLeave (to, from, next) {
        if(to.name !== 'Opened Post') {
            const store = useTagStore();
            store.$reset();
        }
        next();
    },
    data() {
        return {
            initalLoading: true,
            infinityLoading: false,
            error: null,
            numOfPosts: 6,
            apiProccesing: false,
            firstTimeOpened: true,
        };
    },
    created() {
        if(!this.openedPostViewCloseHandler) {
            this.fetchData();
        } else {
            this.openedPostViewCloseHandler = false;
            this.initalLoading = false;
        }
    },
    mounted() {
        this.fetchNewData();
    },
    methods: {
        async fetchData(writenTagName = this.tagName) {
            if(!(this.apiCalls > 0)) {
                await this.$api.getIsolatedTagPosts(
                    writenTagName, 
                    {num: this.numOfPosts, offset: this.numOfPosts * this.apiCalls},
                    this.userStore.accessToken)
                    .then((response) => {
                        if(response.success) {
                            this.apiCalls++;
                            this.posts = response.posts;
                            this.error = response.errMsg;
                            this.tagNameCache = writenTagName;
                        } else {
                            this.numOfPosts = 6;
                            this.postOffset = 0;
                            this.apiCalls = 0;
                            this.error = response.errMsg;
                        }
                    })

            }
            this.initalLoading = false;
        },
        closeTag() {
            this.$router.push({ path: '/' })
        },
        createUniqueKey(postId, i) {
            return `${postId}-${this.tagName}-${i}`;
        },
        fullPostClosed() {
        },
        fetchNewData() {
            window.onscroll = async () => {
                let bottomOfWindow = document.documentElement.scrollHeight - document.documentElement.scrollTop === document.documentElement.clientHeight;
                // A littel hacky but neccesery
                if(this.firstTimeOpened) {
                    this.firstTimeOpened = false;
                    return;
                }

                if (bottomOfWindow && !this.apiProccesing) {
                    this.infinityLoading = true;

                    this.apiProccesing = true;
                    await this.$api.getIsolatedTagPosts(
                        this.tagName, 
                        { num: this.numOfPosts, offset: this.numOfPosts * this.apiCalls },
                        this.userStore.accessToken)
                        .then((response) => {
                            if(response.success) {
                                if(response.posts.length === 0) {
                                    this.error = response.errMsg;
                                } else {
                                    this.posts.push.apply(this.posts, response.posts);
                                    this.apiCalls++;
                                }

                                this.error = response.errMsg;       
                                this.infinityLoading = false;
                                this.apiProccesing = false;
                            } 
                        })
                }
            };
        }
    },
    computed: {
        ...mapStores(useUserStore),
        ...mapWritableState(useTagStore, ['posts']),
        ...mapWritableState(useTagStore, ['apiCalls']),
        ...mapWritableState(useTagStore, ['openedPostViewCloseHandler']),
        ...mapWritableState(useTagStore, ['tagNameCache']),
    },
}
</script>
<style lang="scss" scoped>
    .isolated-tag {
        margin-top: 80px;
        .navbar {
            top: 0;
            width: 100%;
            position: fixed;
            z-index: 100;
        }

        &-layout {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: repeat(4, auto);
            grid-template-areas: 
                ". header upcoming-events"
                ". posts upcoming-events"
                ". loader .";

            margin-top: 20px;
            color: #fff;
        }

        &-header {
            grid-area: header;

            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: var(--dark);
            border-radius: 15px;
            padding: 40px;

            &--options {
                background: var(--background-light);
                border-radius: 15px;

                width: 100%;
                height: 100%;
                max-width: 50px;
                max-height: 50px;

                @extend .pointer;

                // animation: pulse .3s linear;
                &--bg {
                    width: 100%;
                    height: 100%;

                    background-image: url('../assets/icons/x.svg');
                    @include set-element-background-image();
                }
            }
        }

        &-events {
            grid-area: upcoming-events;

            width: 90%;
            height: fit-content;
            
            margin: 0 auto;
            background-color: var(--dark);            
            border-radius: 15px;
        }

        &-posts {
            grid-area: posts;
        }

        &-loader {
            grid-area: loader;
            margin: 20px auto;

            .loader {
                display: inline-block;
                position: relative;
                width: 100px;
                height: 100px;
            

                div {
                    box-sizing: border-box;
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    margin: 8px;
                    border: 8px solid var(--orange);
                    border-radius: 50%;
                    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                    border-color: var(--orange) transparent transparent transparent;

                    &:nth-child(1) {
                        animation-delay: -0.45s;
                    }

                    &:nth-child(2) {
                        animation-delay: -0.3s;
                    }

                    &:nth-child(3) {
                        animation-delay: -0.15s;
                    }
                }
            }
        }

        &-error {
            grid-area: loader;
            margin: 20px auto;
        }
    }

    @media screen and (max-width: 992px) {
        .isolated-tag {
            margin-top: 140px;

            &-layout {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: repeat(4, auto);
                grid-template-areas: 
                    "upcoming-events header"
                    "upcoming-events posts"
                    "loader .";

                margin-top: 20px;
                margin-right: 20px;
                color: #fff;
            } 
        }
    }

    @media screen and (max-width: 768px) {
        .isolated-tag {
            &-layout {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(4, auto);
                grid-template-areas: 
                    "header"
                    "upcoming-events"
                    "posts"
                    "loader";

                margin: 20px;
                color: #fff;
            } 

            &-header {
                margin-bottom: 20px;
            }

            &-events {
                width: 100%;
            }
        }
    }
</style>