<template>
  <div>
    <div class="commentsContainer">
      <div class="imgContainer">
        <img class="imgProfile" :src="profileImg" />
      </div>
      <div class="inputContainer">
        <input
          type="text"
          :ref="'commentForm' + postId"
          v-model="text"
          placeholder="Your comment here..."
          maxlength="250"
          @keyup.enter="post"
        />

        <!-- Link preview -->
        <a
          v-if="'preview_img' in preview"
          :href="preview.preview_url"
          class="link-preview"
          target="_blank"
        >
          <img
            :src="preview.preview_img"
            :alt="preview.preview_img"
          />
          <h4>{{ preview.preview_title }}</h4>
          <small>{{ preview.preview_url }}</small>
          <b-icon-trash class="trash" @click="clearPreview"></b-icon-trash>
        </a>
        <!-- Icon -->
        <div ref="emojiPicker" class="emoji-picker-container">
          <div 
            @click="toggleEmojiPicker" 
            class="emoji-picker-toggler"></div>
          <Picker 
            v-show="showEmojiSelector"
            emoji="smile"
            :color="'var(--orange)'"
            :showPreview="false"
            :data="emojiIndex" 
            set="twitter"
            @select="emojiSelected" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import { Picker, EmojiIndex } from "emoji-mart-vue-fast";
import { BIconTrash } from "bootstrap-vue";
// twiter emoji sets.
import data from "emoji-mart-vue-fast/data/twitter.json";
// Efan emoji emart css styles
import '../../assets/css/emoji-mart-efan.scss';

let emojiIndex = new EmojiIndex(data, {
  include: ['recent', 'smileys', 'activity'],
  exclude: []
});

export default {
  name: "CommentForm",
  components: { Picker, BIconTrash },
  props: {
    postId: Number,
    postOwnerId: Number,
    isGroupPost: Number,
    groupId: Number,
    clickOnInput : {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      text: "",
      tabId: -1,
      preview: {},
      showEmojiSelector: false,
      emojiIndex: emojiIndex,
    };
  },
  mounted() {
    if(this.clickOnInput) {
      this.$refs[`commentForm${this.postId}`].focus()
    }
  }, 
  methods: {
    handleOutsideClick(event) {
      const emojiPickerSection = this.$refs.emojiPicker.querySelector('section');
      if(emojiPickerSection && !emojiPickerSection.contains(event.target)) {
        this.showEmojiSelector = false;
      }
    },
    toggleEmojiPicker() {
      this.showEmojiSelector = !this.showEmojiSelector;
    },
    emojiSelected(emoji) {
      this.text += `${emoji.native}`;
    },
    async post() {
      if (this.text.length > 0 && this.text.length <= 250) {
        let response = await this.$api.insertComment(
          this.userStore.user.id,
          this.postId,
          this.text,
          this.preview,
          this.postOwnerId,
          null,
          null,
          this.userStore.accessToken
        );
        if (response.success === true) {
          this.text = "";
          this.$emit("refresh");
        }
      }
    },
    async getPreviewData(previewLink) {
      if(!('preview_img' in this.preview)) {
        const response = await this.$api.preview(previewLink);
        if (response.success) {
          this.preview = response.msg;
        }
      }
    },
    clearPreview(e) {
      e.preventDefault();
      this.preview = {};
    },
  },
  computed: {
    ...mapStores(useUserStore),
    profileImg() {
      return this.$formatter.formatProfileImg(this.$store.getImage());
    },
  },
  watch: {
    text(newValue) {
      const httpRegex = /\bhttps?:\/\/\S+/;
      const match = newValue.match(httpRegex);
      if(match) {
        this.getPreviewData(match[0])
      } else {
        this.preview = {}
      }
    },
    showEmojiSelector(value) {
      if(value) {
        setTimeout(() => {
          document.addEventListener('click', this.handleOutsideClick)
        }, 200)
              
      } else {
        document.removeEventListener('click', this.handleOutsideClick)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.commentsContainer {
  display: flex;
  justify-content: flex-start;
  padding: 5px;

  .imgProfile {
    @include profile-img(40px);
    margin-right: 10px;
  }
  .inputContainer {
    position: relative;
    width: 100%;
    border-radius: 15px;
    background-color: var(--dark);
    display: flex;
    flex-direction: column;

    input[type="text"] {
      box-sizing: border-box;
      @include text-input;
      @include remove-outline;
      @include placeholder {
        color: var(--medium);
      }
      background-color: var(--dark);
      color: var(--light);
      width: 100%;
      height: 100%;
      min-height: 40px;
      
      cursor: pointer;
    }

    .link-preview {
      display: grid;
      grid-template-columns: 0.3fr 0.7fr;
      grid-template-areas:
        "img title"
        "img link";
      grid-column-gap: 10px;
      text-decoration: none;
      border: 1px solid var(--text);
      padding: 10px 10px 20px;
      margin: 0px 10px 10px 10px;
      border-radius: 10px;
      position: relative;

      img {
        grid-area: img;
        object-fit: cover;
        width: 100%;
        max-height: 100%;
      }
      h4 {
        grid-area: title;
        color: var(--text);
      }
      small {
        grid-area: link;
        color: var(--text);
        font-size: 12px;
      }
      .trash {
        position: absolute;
        top: 12px;
        right: 12px;
        grid-area: trash;
        color: var(--text);
        &:hover {
          color: var(--orange);
        }
      }
    }

    .emoji-picker {
      &-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: absolute;
        top: 5px;
        right: 8px;
        padding: 3px;
      }

      &-toggler {
        cursor: pointer;
        border-radius: 50%;
        background-image: url("../../assets/icons/emoji-comments.svg");
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
        margin-bottom: 10px;
        transition: transform 0.2s ease-in-out;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

</style>
