<template>
	<a v-if="!isEmpty" target="_blank" rel="noopener noreferrer" :href="url">
		<div class="container">
			<img :src="img">
			<h4>{{ title }}</h4>
		</div>
	</a>
</template>

<script>
	export default {
		name: "LinkPreview",
		props: {
			img: {
				type: String,
				default: ""
			},
			title: {
				type: String,
				default: ""
			},
			url: {
				type: String,
				default: ""
			}
		},
		computed: {
			isEmpty() {
				const _isEmpty = x => (x === "" || x === null || x === false);
				return (_isEmpty(this.img) || _isEmpty(this.title) || _isEmpty(this.url));
			}
		}
	}	
</script>

<style lang="scss" scoped>
	a {
		display: block;
		@include center;
		width: 70%;
		padding: 5px;

		border-radius: 10px;
		text-decoration: none;
		color: var(--orange);
		background-color: var(--dark);
		// margin-left: 0;

		.container {
			img {
				display: block;
				width: 90%;
				max-height: 300px; 
				margin: 0 auto;
				object-fit: cover;
			}
			h4 {
				text-align: center;
				font-size: 0.8em;
				margin: 0;
			}
		}
	}
</style>
